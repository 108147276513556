import React from "react";

//--------- Begin Routes -------//
import profileRoutes from "./@meds/views/profile-main";
import providerRoutes from "./@meds/views/provider-main";
import supplierRoutes from "./@meds/views/supplier-main";
import appointmentHistory from "./@meds/views/appointment-history";
import paymentConditionRoutes from "./@meds/views/payment-condition-main";
import therapeuticClassificationRoutes from "./@meds/views/therapeutic-classification";
import purchaseOrderRelationshipRoutes from "./@meds/views/purchase-order-relationship";
import purchaceOrderApproval from "./@meds/views/purchase-order-approval";
import purchaceOrderReception from "./@meds/views/purchace-order-reception";
import puchaseOrderLiquidation from "./@meds/views/purchase-order-liquidation";
import chronicPatientsRoutes from "./@meds/views/chronic-patients";
import patientsAttended from "./@meds/views/patients-attended";
import priceInquiry from "./@meds/views/price-inquiry";
import operationalMetrics from "./@meds/views/operational-metrics";
import datingMonitoring from "./@meds/views/dating-monitoring";
import stockRoutes from "./@meds/views/stock";
import analysisMethods from "./@meds/views/analysis-methods";
import allergies from "./@meds/views/allergies";
import schema40 from "./@meds/views/schema40";
import purchaseConduce from "./@meds/views/purchase-conduce-relationship";
import PerformanceMetrics from "./@meds/views/performance-metrics";
import Tranferences from "./@meds/views/transferences";
import RegularReturn from "./@meds/views/regular-return";
import DueReturn from "./@meds/views/due-return";
import DisrepairReturn from "./@meds/views/disrepair-return";
import UploadDgiiFile from "./@meds/views/upload-dgii-file";
import SaleReturns from "./@meds/views/saleReturn";
import EffectivenessScheduledAppointments from "./views/effectiveness-scheduleda-appointments/route";
import AppointmentConfirm from "./views/appointment-confirm/route";
import MedicalEquipmentType from "./views/medical-equipment-type/route";
import MedicalEquipment from "./views/medical-equipment/route";
import DailyEquipmentAssignment from "./views/daily-equipment-assignment/route";
import AppointmentRescheduling from "./views/appointment-rescheduling/route";
import ListDiseases from "./views/diagnostic/route";
import DiagnosticGroup from "./views/diagnostic-group/route";
import ScreenKpiContact from "./views/screen-kpi-contact/route";
import RosterCareTeam from "./views/roster_care_team/route";
//--------- End Routes --------//
// const DashboardCostEffectiveness = React.lazy(() =>
//   import("./views/Dashboard/views/cost-effectiveness")
// );

const DashboardCostEffectiveness = React.lazy(() =>
  import("./views/Dashboard2")
);

const Company = React.lazy(() => import("./views/Company"));
const CompanyNew = React.lazy(() => import("./views/CompanyNew"));
const CompanyEdit = React.lazy(() => import("./views/CompanyEdit"));
const Patient = React.lazy(() => import("./views/Patient"));
const PatientList = React.lazy(() => import("./views/PatientList"));
const ProductList = React.lazy(() => import("./views/ProductList"));
const ProductCombinated = React.lazy(() => import("./views/ProductCombinated"));
const ProductAssign = React.lazy(() => import("./views/ProductAssign"));
const ProductCombinatedList = React.lazy(() =>
  import("./views/ProductCombinatedList")
);

const Product = React.lazy(() => import("./views/Product"));
const ProductTypeList = React.lazy(() => import("./views/ProductTypeList"));
const TypeTaxVoucherList = React.lazy(() =>
  import("./views/TypeTaxVoucherList")
);

const AdministrationNCF = React.lazy(() => import("./views/NCF"));
const AdministrationNCFList = React.lazy(() => import("./views/NCFList"));

const Billing = React.lazy(() => import("./views/Billing"));
const RegisterPayment = React.lazy(() => import("./views/RegisterPayment"));
const ProcessPayment = React.lazy(() =>
  import("./views/RegisterPayment/views/payment")
);

const TypeTaxVoucher = React.lazy(() => import("./views/TypeTaxVoucher"));
const SpecialityList = React.lazy(() => import("./views/SpecialityList"));
const AppointmentList = React.lazy(() => import("./views/AppointmentList"));
const Appointment = React.lazy(() => import("./views/Appointment"));
const Speciality = React.lazy(() => import("./views/Speciality"));
const ProductType = React.lazy(() => import("./views/ProductType"));
const BranchOffice = React.lazy(() => import("./views/BranchOffice"));
const ARSPriceView = React.lazy(() => import("./views/ARSPrice"));
const PreEmpleoProductCompany = React.lazy(() =>
  import("./views/PreEmpleoProductCompany")
);

const LPCProductCompany = React.lazy(() => import("./views/LPCProductCompany"));
const FoodProductCompany = React.lazy(() =>
  import("./views/FoodProductCompany")
);

const SettlementView = React.lazy(() => import("./views/Settlement"));
const SettlementEdit = React.lazy(() =>
  import("./views/SettlementProcess/edit/saleSummaries")
);
const EditValue = React.lazy(() =>
  import("./views/SettlementProcess/edit/ValueEdit")
);
const ConciliationListView = React.lazy(() =>
  import("./views/ConciliationList")
);
const ConciliationProcessView = React.lazy(() =>
  import("./views/ConciliationProcess")
);
const SettlementProcessView = React.lazy(() =>
  import("./views/SettlementProcess")
);
const PosView = React.lazy(() => import("./views/Pos"));
const ReprintPosView = React.lazy(() => import("./views/Pos/Reprint"));

const CenterList = React.lazy(() => import("./views/CenterList"));
const CenterManager = React.lazy(() => import("./views/Center"));
const CubicleManager = React.lazy(() => import("./views/Center/views/Cubicle"));

const ARSList = React.lazy(() => import("./views/ARSList"));
const ARS = React.lazy(() => import("./views/ARS"));

//HomePage con el logo de Meds
const HomePage = React.lazy(() => import("./views/Home"));

const MedicalStaff = React.lazy(() => import("./views/MedicalStaff"));
const MedicalStaffManagement = React.lazy(() =>
  import("./views/MedicalStaff/views/management")
);

//Licencias Medicas
const MedicalLicenses = React.lazy(() => import("./views/MedicalLicenses"));
const MedicalLicensesManagement = React.lazy(() =>
  import("./views/MedicalLicenses/views")
);

//metodos de analisis para hemogramas
const analysisMethodsCombinate = React.lazy(() =>
  import("./@meds/views/analysis-methods-homograma")
);

const analysisMethodsCombinateManagement = React.lazy(() =>
  import("./@meds/views/analysis-methods-homograma/views")
);

const UserView = React.lazy(() => import("./views/User"));
const UserManagement = React.lazy(() =>
  import("./views/User/views/management")
);

const MoleculeList = React.lazy(() => import("./views/Molecule"));
const MoleculeManagement = React.lazy(() =>
  import("./views/Molecule/views/management")
);

const ConcentrationList = React.lazy(() => import("./views/Concentration"));
const ConcentrationManagement = React.lazy(() =>
  import("./views/Concentration/views/management")
);
const ConcentrationUnitList = React.lazy(() =>
  import("./views/ConcentrationUnit")
);
const ConcentrationUnitManagement = React.lazy(() =>
  import("./views/ConcentrationUnit/views/management")
);
const PackingList = React.lazy(() => import("./views/Packing"));
const PackingManagement = React.lazy(() =>
  import("./views/Packing/views/management")
);
const ContentManagement = React.lazy(() =>
  import("./views/Content/views/management")
);

const MedicinesList = React.lazy(() => import("./views/Medicines"));
const ContentList = React.lazy(() => import("./views/Content"));
const LineList = React.lazy(() => import("./views/Line"));
const LineManager = React.lazy(() => import("./views/Line/views/management"));
const LineBrandManager = React.lazy(() => import("./views/Line/views/brand"));
const ShapeList = React.lazy(() => import("./views/Shape"));
const AppointmentAgenda = React.lazy(() => import("./views/AppointmentAgenda"));
const AppointmentAdmin = React.lazy(() => import("./views/AppointmentAdmin"));
const AppointmentFrontLine = React.lazy(() =>
  import("./views/AppointmentFrontLine")
);

const ShapeManagement = React.lazy(() =>
  import("./views/Shape/views/management")
);
const GeneralParameterList = React.lazy(() =>
  import("./views/GeneralParameter")
);
const GeneralParameterManagement = React.lazy(() =>
  import("./views/GeneralParameter/views/management")
);

const Budget = React.lazy(() => import("./views/Budget"));
const lpc = React.lazy(() => import("./views/Lpc"));
const CaptureLpc = React.lazy(() => import("./views/Lpc/pages"));
const MedicalParameter = React.lazy(() => import("./views/MedicalParameter"));
const MedicalParameterManagement = React.lazy(() =>
  import("./views/MedicalParameter/views/management")
);
const MedicalParameterGroup = React.lazy(() =>
  import("./views/MedicalParameter/views/group")
);
const MedicalParameterGroupManagement = React.lazy(() =>
  import("./views/MedicalParameter/views/group/management")
);

const AppointmentParameterGroupManagement = React.lazy(() =>
  import("./views/Appointment/views/parameter-groups")
);

const MedicalAttentionProgram = React.lazy(() =>
  import("./views/MedicalAttentionProgram")
);
const MedicalAttentionProgramManagement = React.lazy(() =>
  import("./views/MedicalAttentionProgram/views/management")
);

const AppointmentInternal = React.lazy(() =>
  import("./views/Appointment/views/internals")
);

const AppointmentInternalManagement = React.lazy(() =>
  import("./views/Appointment/views/appointment")
);

const PatientAdmPAM = React.lazy(() =>
  import("./views/MedicalAttentionProgram/patient-adm-pam")
);

const PatientAdmPamManagement = React.lazy(() =>
  import("./views/MedicalAttentionProgram/patient-adm-pam/management")
);
const PatientAdmPamManagementAdd = React.lazy(() =>
  import("./views/MedicalAttentionProgram/patient-adm-pam/add-pam")
);

const PacientTracingAdmPAM = React.lazy(() =>
  import(
    "./views/MedicalAttentionProgram/patient-adm-pam/management/tracing/PatientTracing"
  )
);

const PendingPacientTracingAdmPAM = React.lazy(() =>
  import("./views/MedicalAttentionProgram/patient-adm-pam/pending")
);

const DatingMonitoring = React.lazy(() => import("./views/DatingMonitoring"));

//Consultas Nivel de Inventario
const Inventory = React.lazy(() =>
  import("./@meds/views/stock/pages/management")
);

const detailsProvider = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/details-provider"
  )
);

const detailsProduct = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/details-type-product"
  )
);

const TypeProductForCenter = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/type-pruduct-center"
  )
);

const ProductForTypeCenter = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/type-pruduct-center/product-type-center"
  )
);

const detailsTransactionsCenter = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/type-pruduct-center/product-type-center/details-transactions-center/index"
  )
);

const detailsTransactionsProduct = React.lazy(() =>
  import(
    "./@meds/views/stock/pages/management/tabs/tables/details/details-type-product/details-transactions-product"
  )
);

const appointmentTakeSample = React.lazy(() =>
  import("./views/AppointmentTakeSample")
);

//entradas contables
const AccountingEntry = React.lazy(() => import("./views/AccountingEntry"));

const AccountingEntryManagement = React.lazy(() =>
  import("./views/AccountingEntry/views")
);

//certificationes de pre empleo
const CertificationsPreEmpleo = React.lazy(() =>
  import("./views/CertificationsPreEmpleo")
);

const ResultsPreEmpleo = React.lazy(() =>
  import("./views/CertificationsPreEmpleo/Views")
);

//pacientes en espera
const PatientAwait = React.lazy(() => import("./views/PatientAwait"));

//pacientes en proceso de analiticas
const PatientAnaliticProcess = React.lazy(() =>
  import("./views/LaboratoryAnaliticProcess")
);

//pantalla de Pre Consulta
const PreConsultation = React.lazy(() =>
  import("./views/AppointmentPreConsultation")
);
const InPreConsultation = React.lazy(() =>
  import("./views/AppointmentPreConsultation/views/InPreConsultation")
);

//recuperacion de password
const RestartPassword = React.lazy(() => import("./views/RestartPassword"));

//numeros de autorizaciones
const AutorizationNum = React.lazy(() => import("./views/autorization-num"));

//Metricas Operativas KPI
const CustomerServiceKPI = React.lazy(() =>
  import("./views/customer-services-kpi")
);

//Efectividad de Horas de Atención Médica
const EffectivenessMedicalHours = React.lazy(() =>
  import("./views/effectiveness-medical-hours")
);

//Effective Times List
const EffectiveTimesList = React.lazy(() =>
  import("./views/effective-times-list")
);

//effective time optimum
const EffectiveTimesOptimum = React.lazy(() =>
  import("./views/effective-times-optimum")
);

//motivos de cancelacion de citas
const ReasonCancellation = React.lazy(() =>
  import("./views/reason-cancellation")
);

//captura de radiografia
const XrayCapture = React.lazy(() => import("./views/xray-capture"));
const XrayCaptureDetails = React.lazy(() =>
  import("./views/xray-capture/views")
);

//motivos de cancelacion de citas
const ReasonsForPamSuspension = React.lazy(() =>
  import("./views/reasons_for_pam_suspension")
);

const routes = [
  { path: "/", exact: true },
  //captura de radiografia
  {
    path: "/medical-management/xray-capture",
    name: "Captura de Radiografía",
    exact: true,
    component: XrayCapture
  },
  {
    path: "/medical-management/xray-capture-details/:id",
    name: "Captura de Radiografía",
    exact: true,
    component: XrayCaptureDetails
  },
  //motivos de cancelacion de citas
  {
    path: "/administration/reason-cancellation",
    name: "Motivos de Cancelación de Citas",
    exact: true,
    component: ReasonCancellation
  },
  //motivos de suspencion de Pams
  {
    path: "/patient-management/reasons_for_pam_suspension",
    name: "Motivos de Suspensión de PAM",
    exact: true,
    component: ReasonsForPamSuspension
  },
  //effective time optimum
  {
    path: "/administration/effective-times-optimum",
    name: "Tiempos Optimos de Procesos de Cola",
    exact: true,
    component: EffectiveTimesOptimum
  },
  //Effectiviti Time List
  {
    path: "/dashboard/effective-times-list",
    exact: true,
    name: "Efectividad de Tiempo de Cola",
    component: EffectiveTimesList
  },
  //Efectividad de Horas de Atención Médica
  {
    path: "/dashboard/effectiveness-medical-hours",
    component: EffectivenessMedicalHours,
    exact: true,
    name: "Efectividad de Horas de Atención Médica"
  },

  //metricas Operativas KPI
  {
    path: "/administration/customer-services-kpi",
    component: CustomerServiceKPI,
    exact: true,
    name: "Asignación de KPI's"
  },

  //numero de autorizaciones
  {
    path: "/accounting/autorization-num",
    exact: true,
    name: "Autorizaciones por Comprobante Fiscal",
    component: AutorizationNum
  },
  // Presupuestos
  {
    path: "/accounting/budget",
    exact: true,
    name: "Presupuestos",
    component: Budget
  },
  {
    path: "/lpc/clinical-profile",
    exact: true,
    name: "LPC's",
    component: lpc
  },
  {
    path: "/lpc/clinical-profile/new",
    exact: true,
    name: "Captura de LPC",
    component: CaptureLpc
  },
  {
    path: "/lpc/clinical-profile/:type/:id/:inactive",
    exact: true,
    name: "Editando LPC",
    component: CaptureLpc
  },
  //Paciente en Pre Consulta
  {
    path: "/medical-management/pre-consultation",
    exact: true,
    name: "Pre-Consulta",
    component: PreConsultation
  },
  {
    path:
      "/medical-management/pre-consultation/in-consultation/:id/:appointmentId",
    exact: true,
    name: "En Pre-Consulta",
    component: InPreConsultation
  },
  //recuperacion de password
  {
    path: "/patient-management/restart-password",
    exact: true,
    name: "Recuperacion de Contraseñas",
    component: RestartPassword
  },
  //pacientes en espera
  {
    path: "/operations/patient-await",
    exact: true,
    name: "Pacientes en Espera",
    component: PatientAwait
  },

  //pacientes en proceso de analiticas
  {
    path: "/laboratory/analitics-process",
    exact: true,
    name: "Pacientes en Proceso de Analíticas",
    component: PatientAnaliticProcess
  },
  //entradas contables
  {
    path: "/accounting/accounting-entry",
    exact: true,
    name: "Entradas Contables",
    component: AccountingEntry
  },
  {
    path: "/accounting/accounting-entry/new",
    exact: true,
    name: "Nueva Entrada",
    component: AccountingEntryManagement
  },
  {
    path: "/accounting/accounting-entry/edit/:id",
    exact: true,
    name: "Editar Entrada",
    component: AccountingEntryManagement
  },
  //certificaciones de pre empleo
  {
    path: "/operations/certifications-pre-empleo",
    exact: true,
    name: "Certificaciones de Preempleo",
    component: CertificationsPreEmpleo
  },
  {
    path: "/operations/certifications-pre-empleo/results",
    exact: true,
    name: "Resultados",
    component: ResultsPreEmpleo
  },
  //toma de muestra
  {
    path: "/operations/take-sample",
    exact: true,
    name: "Toma de Muestra",
    component: appointmentTakeSample
  },
  {
    path: "/dashboard/cost-effectiveness",
    exact: true,
    name: "Análisis de Ventas",
    component: DashboardCostEffectiveness
  },
  /*  Consultas - Monitoreo de Citas  */
  {
    path: "/dashboard/inquiries-dating-monitoring",
    exact: true,
    name: "KPI's - Contacto",
    component: DatingMonitoring
  },
  //consultas nivel de inventario
  {
    path: "/dashboard/inventory",
    exact: true,
    name: "Niveles de inventario",
    component: Inventory
  },
  {
    name: "Detalle Por Proveedor",
    component: detailsProvider,
    exact: true,
    path: "/dashboard/inventory/details-provider/:id/:typeID"
  },
  {
    name: "Detalle Por  Tipo de Productos",
    component: detailsProduct,
    exact: true,
    path: "/dashboard/inventory/details-type-product/:id/:typeID"
  },
  {
    name: "Detalle Por Tipo de Producto",
    component: TypeProductForCenter,
    exact: true,
    path: "/dashboard/inventory/type-pruduct-center/:id/:typeID"
  },
  {
    name: "Detalle de Producto por Tipo y Centro",
    component: ProductForTypeCenter,
    exact: true,
    path:
      "/dashboard/inventory/type-pruduct-center/id/typeID/product-type-center/:id/:typeID/:invType"
  },
  {
    name: "Detalle de Transacciones de Inventario",
    component: detailsTransactionsProduct,
    exact: true,
    path:
      "/dashboard/inventory/details-type-product/id/details-transactions-product/:id/:typeId/:transID"
  },
  {
    name: "Detalle de Transacciones de Inventario por Sucursal",
    component: detailsTransactionsCenter,
    exact: true,
    path:
      "/dashboard/inventory/type-pruduct-center/id/typeID/product-type-center/details-transactions-center/:CenterId/:typeId/:pID/:invType"
  },
  //!modulo de los medicamentos movidos
  {
    path: "/dashboard/medicines",
    exact: true,
    name: "Medicamentos",
    component: MedicinesList
  },
  // {
  //   path: "/dashboard/medicines/new", //!eliminado
  //   exact: true,
  //   name: "Nuevo Medicamento",
  //   component: MedicinesManagement
  // },
  // {
  //   path: "/dashboard/medicines/edit/:medicines_id", //!eliminado
  //   exact: true,
  //   name: "Editar Medicamento",
  //   component: MedicinesManagement
  // },

  //Frente de linea
  {
    path: "/operations/appointment-front-line",
    exact: true,
    name: "Frente de Línea",
    component: AppointmentFrontLine
  },
  {
    path: "/accounting/reconciliations",
    exact: true,
    name: "Conciliacion de Cuadres",
    component: ConciliationListView
  },

  {
    path: "/accounting/reconciliations/process/:id",
    exact: true,
    name: "Proceso de conciliacion",
    component: ConciliationProcessView
  },

  {
    path: "/operations/settlements",
    exact: true,
    name: "Procesamiento de Cuadres",
    component: SettlementView
  },
  {
    path: "/operations/settlements/process/:id",
    exact: true,
    name: "Proceso de Cierre",
    component: SettlementProcessView
  },
  {
    path: "/operations/settlements/process/:id/summary",
    exact: true,
    name: "Revision de Cuadre de Caja",
    component: SettlementEdit
  },

  {
    path: "/operations/settlements/process/:id/summary/:summary_id",
    exact: true,
    name: "Modificar Factura",
    component: EditValue
  },

  //Operations
  {
    path: "/operations/companies/new",
    exact: true,
    name: "Crear Empresa",
    component: CompanyNew
  },
  {
    path: "/operations/companies/edit/:company_id",
    exact: true,
    name: "Editar Empresa",
    component: CompanyEdit
  },
  {
    path: "/operations/companies/edit/:company_id/office/new",
    exact: true,
    name: "Nueva Sucursal",
    component: BranchOffice
  },
  {
    path:
      "/operations/companies/edit/:company_id/office/edit/:branch_office_id",
    exact: true,
    name: "Editar Sucursal",
    component: BranchOffice
  },
  {
    path: "/operations/patients/new",
    exact: true,
    name: "Nuevo Paciente",
    component: Patient
  },
  {
    path: "/operations/patients/edit/:patient_id",
    exact: true,
    name: "Editar Paciente",
    component: Patient
  },

  {
    path: "/operations/patients",
    exact: true,
    name: "Pacientes",
    component: PatientList
  },
  {
    path: "/operations/companies",
    exact: true,
    name: "Empresas",
    component: Company
  },
  {
    path: "/operations/pos",
    exact: true,
    name: "Punto de Venta",
    component: PosView
  },
  {
    path: "/operations/pos/reprint",
    exact: true,
    name: "Reimpresion",
    component: ReprintPosView
  },

  //Services
  {
    path: "/services/products",
    exact: true,
    name: "Productos",
    component: ProductList
  },
  {
    path: "/services/specialities",
    exact: true,
    name: "Especialidades",
    component: SpecialityList
  },
  {
    path: "/services/appointments",
    exact: true,
    name: "Citas Médicas - Tipos",
    component: AppointmentList
  },
  {
    path: "/services/productTypes",
    exact: true,
    name: "Tipos de Productos",
    component: ProductTypeList
  },

  {
    path: "/accounting/typetaxvouchers",
    exact: true,
    name: "Comprobantes Fiscales - Tipos",
    component: TypeTaxVoucherList
  },
  {
    path: "/accounting/typetaxvouchers/new",
    exact: true,
    name: "Nuevo Tipo Comprobante Fiscal",
    component: TypeTaxVoucher
  },
  {
    path: "/accounting/typetaxvouchers/edit/:id",
    exact: true,
    name: "Editar Tipo Comprobante Fiscal",
    component: TypeTaxVoucher
  },

  {
    path: "/accounting/ncf",
    exact: true,
    name: "Comprobantes Fiscales - Administración",
    component: AdministrationNCFList
  },
  {
    path: "/accounting/ncf/edit/:id",
    exact: true,
    name: "Editar NCF",
    component: AdministrationNCF
  },

  {
    path: "/services/combinated",
    exact: true,
    name: "Productos Combinados",
    component: ProductCombinatedList
  },

  {
    path: "/services/specialities/new",
    exact: true,
    name: "Nuevo Especialidad",
    component: Speciality
  },
  {
    path: "/services/specialities/edit/:speciality_id",
    exact: true,
    name: "Editar Especialidad",
    component: Speciality
  },
  {
    path: "/services/productTypes/new",
    exact: true,
    name: "Nuevo Tipo de Producto",
    component: ProductType
  },
  {
    path: "/services/productTypes/edit/:product_type_id",
    exact: true,
    name: "Editar Tipo de Producto",
    component: ProductType
  },

  {
    path: "/services/combinated/new",
    exact: true,
    name: "Nuevo Producto Combinado",
    component: ProductCombinated
  },
  {
    path: "/services/combinated/edit/:products_id/:is_redirect",
    exact: true,
    name: "Editar Producto Combinado",
    component: ProductCombinated
  },
  {
    path: "/services/combinated/edit/:product_id/assign/new",
    exact: true,
    name: "Asignar Producto",
    component: ProductAssign
  },

  {
    path: "/services/appointments/new",
    exact: true,
    name: "Nueva Cita Medica",
    component: Appointment
  },
  {
    path: "/services/appointments/edit/:products_id",
    exact: true,
    name: "Editar Cita Medica",
    component: Appointment
  },

  {
    path: "/services/products/new",
    exact: true,
    name: "Nuevo Producto",
    component: Product
  },
  {
    path: "/services/products/edit/:products_id",
    exact: true,
    name: "Editar Producto",
    component: Product
  },

  {
    path: "/services/products/edit/:product_id/arsprice/new",
    exact: true,
    name: "Nuevo Precio ARS",
    component: ARSPriceView
  },
  {
    path: "/services/products/edit/:product_id/arsprice/edit/:ars_price_id",
    exact: true,
    name: "Editar Precio ARS",
    component: ARSPriceView
  },
  {
    path: "/services/appointments/edit/:product_id/arsprice/new",
    exact: true,
    name: "Nuevo Precio ARS",
    component: ARSPriceView
  },
  {
    path: "/services/appointments/edit/:product_id/arsprice/edit/:ars_price_id",
    exact: true,
    name: "Editar Precio ARS",
    component: ARSPriceView
  },

  {
    path:
      "/services/products/edit/:product_id/preempleoproductcompany/new/:isCombinate/:com_id",
    exact: true,
    name: "Nuevo Pre-Empleo",
    component: PreEmpleoProductCompany
  },
  {
    path:
      "/services/products/edit/:product_id/preempleoproductcompany/edit/:pre_empleo_id/:isCombinate/:com_id",
    exact: true,
    name: "Editar Pre-Empleo",
    component: PreEmpleoProductCompany
  },

  // LPC Product Price
  {
    path:
      "/services/products/edit/:product_id/lpcproductcompany/new/:isCombinate/:com_id",
    exact: true,
    name: "Nuevo LPC",
    component: LPCProductCompany
  },
  {
    path:
      "/services/products/edit/:product_id/lpcproductcompany/edit/:lpc_id/:isCombinate/:com_id",
    exact: true,
    name: "Editar LPC",
    component: LPCProductCompany
  },

  {
    path:
      "/services/products/edit/:product_id/foodanddrink/new/:isCombinate/:com_id",
    exact: true,
    name: "Nuevo A&B",
    component: FoodProductCompany
  },
  {
    path:
      "/services/products/edit/:product_id/foodanddrink/edit/:ab_id/:isCombinate/:com_id",
    exact: true,
    name: "Editar A&B",
    component: FoodProductCompany
  },

  {
    path: "/services/appointments/edit/:product_id/preempleoproductcompany/new",
    exact: true,
    name: "Nuevo Pre-Empleo",
    component: PreEmpleoProductCompany
  },
  {
    path:
      "/services/appointments/edit/:product_id/preempleoproductcompany/edit/:pre_empleo_id",
    exact: true,
    name: "Editar Pre-Empleo",
    component: PreEmpleoProductCompany
  },
  {
    path: "/services/ars",
    exact: true,
    name: "Aseguradoras",
    component: ARSList
  },
  {
    path: "/services/ars/new",
    exact: true,
    name: "Nueva ARS",
    component: ARS
  },
  {
    path: "/services/ars/edit/:ars_id",
    exact: true,
    name: "Editar ARS",
    component: ARS
  },
  {
    path: "/accounting/billing",
    exact: true,
    name: "Facturación",
    component: Billing
  },
  {
    path: "/accounting/register-payment",
    exact: true,
    name: "Registro de Cobro",
    component: RegisterPayment
  },
  {
    path: "/accounting/register-payment/process-payment/:billing_id",
    exact: true,
    name: "Agregar Cobro",
    component: ProcessPayment
  },
  {
    path: "/operations/centers",
    exact: true,
    name: "Centros",
    component: CenterList
  },
  {
    path: "/operations/centers/new",
    exact: true,
    name: "Nuevo Centro",
    component: CenterManager
  },
  {
    path: "/operations/centers/edit/:center_id",
    exact: true,
    name: "Editar Centro",
    component: CenterManager
  },
  {
    path: "/operations/centers/edit/:center_id/cubicle/new",
    exact: true,
    name: "Nuevo Cubiculo",
    component: CubicleManager
  },
  {
    path: "/operations/centers/edit/:center_id/cubicle/edit/:cubicle_id",
    exact: true,
    name: "Editar Cubiculo",
    component: CubicleManager
  },
  // home page
  {
    path: "/",
    exact: true,
    name: "Home",
    component: HomePage
  },
  {
    path: "/operations/medical-licenses",
    exact: true,
    name: "Licencias Médicas - Tipos",
    component: MedicalLicenses
  },
  {
    path: "/operations/medical-licenses/new",
    exact: true,
    name: "Nueva Licencia Medica",
    component: MedicalLicensesManagement
  },
  {
    path: "/operations/medical-licenses/edit/:medical_licenses_id",
    exact: true,
    name: "Editar Licencias Medica",
    component: MedicalLicensesManagement
  },
  //rutas para los metodos de analisis de los hemogramas
  {
    path: "/administration/analysis-methods-hemograma",
    exact: true,
    name: "Laboratorio - Parámetros Pruebas Combinadas",
    component: analysisMethodsCombinate
  },
  {
    path: "/administration/analysis-methods-hemograma/new",
    exact: true,
    name: "Nuevo Paramétro de Pruebas Combinadas",
    component: analysisMethodsCombinateManagement
  },
  {
    path: "/administration/analysis-methods-hemograma/edit/:id",
    exact: true,
    name: "Editar Paramétro de Pruebas Combinadas",
    component: analysisMethodsCombinateManagement
  },
  {
    path: "/hmanagement/mstaffs",
    exact: true,
    name: "Personal de Cuidados",
    component: MedicalStaff
  },
  {
    path: "/hmanagement/mstaffs/new",
    exact: true,
    name: "Nuevo Personal Médico",
    component: MedicalStaffManagement
  },
  {
    path: "/hmanagement/mstaffs/edit/:medical_staff_id",
    exact: true,
    name: "Editar Personal Médico",
    component: MedicalStaffManagement
  },
  {
    path: "/hmanagement/users",
    exact: true,
    name: "Usuarios",
    component: UserView
  },
  {
    path: "/hmanagement/users/new",
    exact: true,
    name: "Nuevo Usuario",
    component: UserManagement
  },
  {
    path: "/hmanagement/users/edit/:user_id/:is_collaborator",
    exact: true,
    name: "Editar Usuario",
    component: UserManagement
  },
  {
    path: "/medicines/molecules",
    exact: true,
    name: "Moléculas",
    component: MoleculeList
  },
  {
    path: "/medicines/molecules/new",
    exact: true,
    name: "Nueva Molécula",
    component: MoleculeManagement
  },
  {
    path: "/medicines/molecules/edit/:molecule_id",
    exact: true,
    name: "Editar Molécula",
    component: MoleculeManagement
  },
  {
    path: "/medicines/concentrations",
    exact: true,
    name: "Concentración",
    component: ConcentrationList
  },
  {
    path: "/medicines/concentrations/new",
    exact: true,
    name: "Nueva Concentración",
    component: ConcentrationManagement
  },
  {
    path: "/medicines/concentrations/edit/:concentration_id",
    exact: true,
    name: "Editar Concentración",
    component: ConcentrationManagement
  },

  {
    path: "/medicines/concentration-units",
    exact: true,
    name: "Unidad Concentración",
    component: ConcentrationUnitList
  },
  {
    path: "/medicines/concentration-units/new",
    exact: true,
    name: "Nueva Unidad Concentración",
    component: ConcentrationUnitManagement
  },
  {
    path: "/medicines/concentration-units/edit/:concentration_unit_id",
    exact: true,
    name: "Editar Unidad Concentración",
    component: ConcentrationUnitManagement
  },

  {
    path: "/medicines/packings",
    exact: true,
    name: "Empaques",
    component: PackingList
  },
  {
    path: "/medicines/packings/new",
    exact: true,
    name: "Nuevo Empaque",
    component: PackingManagement
  },
  {
    path: "/medicines/packings/edit/:packing_id",
    exact: true,
    name: "Editar Empaque",
    component: PackingManagement
  },
  {
    path: "/medicines/contents",
    exact: true,
    name: "Contenidos",
    component: ContentList
  },
  {
    path: "/medicines/contents/new",
    exact: true,
    name: "Nuevo Contenido",
    component: ContentManagement
  },
  {
    path: "/medicines/contents/edit/:content_id",
    exact: true,
    name: "Editar Contenido",
    component: ContentManagement
  },
  {
    path: "/medicines/shapes",
    exact: true,
    name: "Formas",
    component: ShapeList
  },
  {
    path: "/medicines/shapes/new",
    exact: true,
    name: "Nueva Forma",
    component: ShapeManagement
  },
  {
    path: "/medicines/shapes/edit/:shape_id",
    exact: true,
    name: "Editar Forma",
    component: ShapeManagement
  },
  // {
  //   path: "/medicines/medicines",
  //   exact: true,
  //   name: "Medicamentos",
  //   component: MedicinesList
  // },
  // {
  //   path: "/medicines/medicines/new",
  //   exact: true,
  //   name: "Nuevo Medicamento",
  //   component: MedicinesManagement
  // },
  // {
  //   path: "/medicines/medicines/edit/:medicines_id",
  //   exact: true,
  //   name: "Editar Medicamento",
  //   component: MedicinesManagement
  // },

  {
    path: "/medicines/lines",
    exact: true,
    name: "Lineas",
    component: LineList
  },
  {
    path: "/operations/appointment-agenda",
    exact: true,
    name: "Citas Médicas - Agendamiento",
    component: AppointmentAgenda
  },
  {
    path: "/medicines/lines/new",
    exact: true,
    name: "Nueva Linea",
    component: LineManager
  },
  {
    path: "/medicines/lines/edit/:line_id",
    exact: true,
    name: "Editar Linea",
    component: LineManager
  },
  {
    path: "/medicines/lines/edit/:line_id/brand/new",
    exact: true,
    name: "Nueva Marca",
    component: LineBrandManager
  },
  {
    path: "/medicines/lines/edit/:line_id/brand/:brand_id",
    exact: true,
    name: "Nueva Marca",
    component: LineBrandManager
  },
  {
    path: "/accounting/parameters",
    exact: true,
    name: "Parametros Generales",
    component: GeneralParameterList
  },
  {
    path: "/accounting/parameters/edit/:parameter_id",
    exact: true,
    name: "Editar Parametros Generales",
    component: GeneralParameterManagement
  },
  {
    path: "/accounting/parameters/new",
    exact: true,
    name: "Crear Parametro General",
    component: GeneralParameterManagement
  },
  {
    path: "/operations/appointment-admin",
    exact: true,
    name: "Citas Médicas - Administración",
    component: AppointmentAdmin
  },
  {
    path: "/operations/medical-parameters",
    exact: true,
    name: "Parámetros Médicos - Administración",
    component: MedicalParameter
  },
  {
    path: "/operations/medical-parameters/new",
    exact: true,
    name: "Nuevo Parámetro Médico",
    component: MedicalParameterManagement
  },
  {
    path: "/operations/medical-parameters/edit/:id",
    exact: true,
    name: "Editar Parámetro Médico",
    component: MedicalParameterManagement
  },
  {
    path: "/operations/medical-parameter-groups",
    exact: true,
    name: "Parámetros Medicos - Grupos",
    component: MedicalParameterGroup
  },
  {
    path: "/operations/medical-parameter-groups/new",
    exact: true,
    name: "Nuevo Grupo de Parámetro Médico",
    component: MedicalParameterGroupManagement
  },
  {
    path: "/operations/medical-parameter-groups/edit/:id",
    exact: true,
    name: "Editar Grupo de Parámetro Médico",
    component: MedicalParameterGroupManagement
  },
  {
    path: "/services/appointments/edit/:id/medical-parameter-groups",
    exact: true,
    name: "Asignar o Deasignar Grupo de Parámetro Médico",
    component: AppointmentParameterGroupManagement
  },
  {
    path: "/operations/medical-attention-programs",
    exact: true,
    name: "Programas de Atención Médica (PAM's)",
    component: MedicalAttentionProgram
  },
  {
    path: "/operations/medical-attention-programs/new",
    exact: true,
    name: "Nuevo Programa de Atención Medica",
    component: MedicalAttentionProgramManagement
  },
  {
    path: "/operations/medical-attention-programs/edit/:id",
    exact: true,
    name: "Editar Programa de Atención Medica",
    component: MedicalAttentionProgramManagement
  },
  {
    path: "/operations/appointment-internal",
    exact: true,
    name: "Citas Médicas - Ejecución",
    component: AppointmentInternal
  },
  {
    path: "/operations/appointment-internal/appointment/:id",
    exact: true,
    name: "Cita Medica",
    component: AppointmentInternalManagement
  },
  {
    path: "/operations/patient-medical-attention-programs",
    exact: true,
    name: "Pacientes en PAM's - Administración",
    component: PatientAdmPAM
  },
  {
    path: "/operations/patient-medical-attention-programs/new",
    exact: true,
    name: "Paciente PAM",
    component: PatientAdmPamManagementAdd
  },
  {
    path: "/operations/patient-medical-attention-programs/edit/:id",
    exact: true,
    name: "Paciente PAM",
    component: PatientAdmPamManagement
  },
  {
    path:
      "/operations/patient-medical-attention-programs/tracing/:id/:patientID/",
    exact: true,
    name: "Seguimiento Paciente PAM",
    component: PacientTracingAdmPAM
  },
  {
    path:
      "/operations/patient-medical-attention-programs/pending-patient-medical-attention-programs",
    exact: true,
    name: "Citas Pendientes de PAM's",
    component: PendingPacientTracingAdmPAM
  },
  {},

  ...profileRoutes,
  ...providerRoutes,
  ...supplierRoutes,
  ...appointmentHistory,
  ...paymentConditionRoutes,
  ...therapeuticClassificationRoutes,
  ...purchaseOrderRelationshipRoutes,
  ...purchaceOrderApproval,
  ...purchaceOrderReception,
  ...puchaseOrderLiquidation,
  ...chronicPatientsRoutes,
  ...patientsAttended,
  ...priceInquiry,
  ...operationalMetrics,
  ...datingMonitoring,
  ...stockRoutes,
  ...analysisMethods,
  ...allergies,
  ...schema40,
  ...purchaseConduce,
  ...PerformanceMetrics,
  ...Tranferences,
  ...RegularReturn,
  ...DueReturn,
  ...DisrepairReturn,
  ...UploadDgiiFile,
  ...SaleReturns,
  ...EffectivenessScheduledAppointments,
  ...AppointmentConfirm,
  ...MedicalEquipmentType,
  ...MedicalEquipment,
  ...DailyEquipmentAssignment,
  ...AppointmentRescheduling,
  ...ListDiseases,
  ...DiagnosticGroup,
  ...ScreenKpiContact,
  ...RosterCareTeam
];

export default routes;
