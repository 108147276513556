module.exports = {
  items: [
    {
      name: "Consultas",
      url: "/dashboard",
      icon: "fa-solid fa-chart-simple",
      children: [
        {
          name: "Análisis de Ventas",
          url: "/dashboard/cost-effectiveness",
          icon: "fa-solid fa-chart-bar",
          permission: "dashboard_cost-effectiveness"
        },
        {
          name: "Cartera de Pacientes Crónicos",
          url: "/dashboard/chronic-patients",
          icon: "fa-solid fa-user-injured",
          permission: "dashboard_chronic-patients"
        },
        {
          name: "Efectividad de Citas Agendadas",
          url: "/dashboard/effectiveness-scheduleda-appointments",
          icon: "fa-solid fa-calendar-check",
          permission: "dashboard_effectiveness-scheduleda-appointments"
        },
        {
          name: "Efectividad de Horas de Atención Médica",
          url: "/dashboard/effectiveness-medical-hours",
          icon: "fa-solid fa-clock",
          permission: "dashboard_effectiveness-medical-hours"
        },
        {
          name: "Efectividad de Tiempo de Cola",
          url: "/dashboard/effective-times-list",
          icon: "fa-solid fa-hourglass-half",
          permission: "dashboard_effective-times-list"
        },
        {
          name: "Esquema 40 - SISALRIL",
          url: "/dashboard/schema40",
          icon: "fa-solid fa-archive",
          permission: "dashboard_schema40"
        },
        {
          name: "KPI's - Atenciones",
          url: "/dashboard/operational-metrics",
          icon: "fa-solid fa-tachometer-alt",
          permission: "dashboard_operational-metrics"
        },
        {
          name: "KPI's - Contacto",
          url: "/dashboard/inquiries-dating-monitoring",
          icon: "fa-solid fa-phone",
          permission: "dashboard_inquiries-dating-monitoring"
        },
        {
          name: "KPI's - Cuidados",
          url: "/dashboard/performance-metrics-regular",
          icon: "fa-solid fa-tachometer-alt",
          permission: "dashboard_performace-metrics"
        },
        {
          name: "Medicamentos",
          icon: "fa-solid fa-medkit",
          url: "/dashboard/medicines",
          permission: "medicine_medicines"
        },
        {
          name: "Niveles de inventario",
          url: "/dashboard/inventory",
          icon: "fa-solid fa-box",
          permission: "dashboard_inventory"
        },
        {
          name: "Pacientes Atendidos - MISPAS",
          url: "/dashboard/patients-attended",
          icon: "fa-solid fa-users",
          permission: "dashboard_patients-attended"
        },
        {
          name: "Precios de Productos",
          url: "/dashboard/price-inquiry",
          icon: "fa-solid fa-dollar-sign",
          permission: "dashboard_price-inquiry"
        },
        {
          name: "Roster - Equipo Cuidados",
          url: "/dashboard/roster_care_team",
          icon: "fa-solid fa-chart-simple",
          permission: "dashboard-roster_care_team"
        }
      ]
    },
    {
      name: "Gestión",
      url: "/administration",
      icon: "fa-solid fa-tachometer-alt",
      children: [
        {
          name: "Aseguradoras",
          url: "/services/ars",
          icon: "fa-solid fa-hospital",
          permission: "administration_arses"
        },
        {
          name: "Asignación de KPI's",
          icon: "fa-solid fa-chart-line",
          url: "/administration/customer-services-kpi",
          permission: "administration_customer_service_kpi"
        },
        {
          name: "Grupos de Enfermedades - CIE",
          url: "/management/diagnostic-group",
          icon: "fa fa-file-text",
          permission: "administration_diagnostic-group"
        },
        {
          name: "Parametros Generales",
          url: "/accounting/parameters",
          icon: "fa-solid fa-cogs",
          permission: "administration_general-parameters"
        },
        {
          name: "Perfiles",
          url: "/hmanagement/profile-list",
          icon: "fa-solid fa-users",
          permission: "hmanagement_profile"
        },
        {
          name: "Presupuestos",
          url: "/accounting/budget",
          icon: "fa-solid fa-wallet",
          permission: "administration_budget"
        },
        {
          name: "Productos",
          url: "/services/products",
          icon: "fa-solid fa-box",
          permission: "administration_products"
        },
        {
          name: "Productos Combinados",
          url: "/services/combinated",
          icon: "fa-solid fa-th-large",
          permission: "administration_products-combinated"
        },
        {
          name: "Relación de Enfermedades - CIE",
          url: "/management/diagnostic",
          icon: "fa fa-file-text",
          permission: "administration_diagnostic"
        },
        {
          name: "Tiempos Óptimos de Procesos de Cola",
          url: "/administration/effective-times-optimum",
          icon: "fa-solid fa-clock",
          permission: "administration_effective-times-optimum"
        },
        {
          name: "Tipos de Productos",
          url: "/services/productTypes",
          icon: "fa-solid fa-th",
          permission: "administration_products-types"
        }
      ]
    },
    {
      name: "Gente",
      url: "/hmanagement",
      icon: "fa-solid fa-user-md",
      children: [
        {
          name: "Usuarios",
          icon: "fa-solid fa-users",
          url: "/hmanagement/users",
          permission: "hmanagement_users"
        }
      ]
    },
    {
      name: "Números",
      url: "/accounting",
      icon: "fa-solid fa-calculator",
      children: [
        {
          name: "Autorizaciones por Comprobante Fiscal",
          url: "/accounting/autorization-num",
          icon: "fa-solid fa-file-alt",
          permission: "accounting_autorization_num"
        },
        {
          name: "Cargar Archivo de la DGII",
          icon: "fa-solid fa-upload",
          url: "/accounting/upload-dgii-file",
          permission: "accounting_upload-dgii-file"
        },
        {
          name: "Compras - Liquidación",
          url: "/shopping/purchase-order-liquidation",
          icon: "fa-solid fa-external-link-alt",
          permission: "shopping_purchase-order-liquidation"
        },
        {
          url: "/accounting/ncf",
          icon: "fa-solid fa-file-alt",
          permission: "accounting_ncf",
          name: "Comprobantes Fiscales - Administración"
        },
        {
          icon: "fa-solid fa-file-word",
          name: "Comprobantes Fiscales - Tipos",
          url: "/accounting/typetaxvouchers",
          permission: "accounting_typetaxvouchers"
        },
        {
          icon: "fa-solid fa-pencil-alt",
          name: "Conciliación de Cuadres",
          url: "/accounting/reconciliations",
          permission: "accounting_reconciliations"
        },
        {
          icon: "fa-solid fa-dollar-sign",
          name: "Condiciones de Pago",
          url: "/accounting/payment-condition-list",
          permission: "accounting_payment-condition-list"
        },
        {
          name: "Empresas",
          icon: "fa-solid fa-building",
          url: "/operations/companies",
          permission: "operation_companies"
        },
        {
          icon: "fa-solid fa-book",
          name: "Entradas Contables",
          url: "/accounting/accounting-entry",
          permission: "accounting_entry"
        },
        {
          name: "Facturación",
          icon: "fa-solid fa-calculator",
          url: "/accounting/Billing",
          permission: "accounting_billing"
        },
        {
          name: "Proveedores",
          icon: "fa-solid fa-building",
          url: "/accounting/providers-list",
          permission: "accounting_providers-list"
        },
        {
          icon: "fa-solid fa-money-bill-wave",
          name: "Registro de Cobro",
          url: "/accounting/register-payment",
          permission: "accounting_register-payment"
        },
        {
          name: "Suplidores",
          icon: "fa-solid fa-box",
          url: "/accounting/suppliers-list",
          permission: "accounting_suppliers-list"
        }
      ]
    },
    {
      name: "Atenciones",
      url: "/operations",
      icon: "fa-solid fa-heartbeat",
      children: [
        {
          name: "Centros",
          icon: "fa-solid fa-building",
          url: "/operations/centers",
          permission: "operation_centers"
        },
        {
          name: "Certificaciones de Preempleo",
          url: "/operations/certifications-pre-empleo",
          icon: "fa-solid fa-list-alt",
          permission: "patient-management_certifications-pre-empleo"
        },
        {
          name: "Compras Proveedores - Aprobación",
          url: "/shopping/purchase-order-approval-provider",
          icon: "fa-solid fa-thumbs-up",
          permission: "shopping_purchase-order-approval-provider"
        },
        {
          name: "Compras Proveedores - Generación",
          url: "/shopping/purchase-order-relationship-provider",
          icon: "fa-solid fa-american-sign-language-interpreting",
          permission: "shopping_purchase-order-relationship-provider"
        },
        {
          name: "Compras Proveedores - Recepción",
          url: "/shopping/purchase-order-reception-provider",
          icon: "fa-solid fa-thumbtack",
          permission: "shopping_purchase-order-reception-provider"
        },
        {
          name: "Compras Suplidores - Aprobación",
          url: "/shopping/purchase-order-approval-supplier",
          icon: "fa-solid fa-thumbs-up",
          permission: "shopping_purchase-order-approval-supplier"
        },
        {
          name: "Compras Suplidores - Generación",
          url: "/shopping/purchase-order-relationship-supplier",
          icon: "fa-solid fa-american-sign-language-interpreting",
          permission: "shopping_purchase-order-relationship-supplier"
        },
        {
          name: "Compras Suplidores - Recepción",
          url: "/shopping/purchase-order-reception-supplier",
          icon: "fa-solid fa-thumbtack",
          permission: "shopping_purchase-order-reception-supplier"
        },
        {
          name: "Devoluciones Mal Estado",
          url: "/disrepair-return",
          icon: "fa-solid fa-external-link",
          permission: "shopping_bad-condition-return"
        },
        {
          name: "Devoluciones Regulares",
          url: "/regular-return",
          icon: "fa-solid fa-external-link",
          permission: "shopping_regular-return"
        },
        {
          name: "Devoluciones Vencidos",
          url: "/due-return",
          icon: "fa-solid fa-external-link",
          permission: "shopping_due-return"
        },
        {
          name: "Devoluciones de Ventas",
          url: "/sale-return/history",
          icon: "fa-solid fa-shopping-bag",
          permission: "administration_sale-returns"
        },
        {
          name: "Equipos Médicos - Administración",
          url: "/medical-management/medical-equipment",
          icon: "fa-solid fa-wrench",
          permission: "medical-management_medical-equipment"
        },
        {
          name: "Equipos Médicos - Asignación Diaria",
          url: "/center-management/daily-equipment-assignment",
          icon: "fa-solid fa-wrench",
          permission: "center-management_daily-equipment-assignment"
        },
        {
          name: "Equipos Médicos - Tipos",
          url: "/administration/medical-equipment-type",
          icon: "fa-solid fa-th",
          permission: "administration_medical-equipment-type"
        },
        {
          name: "Frente de Línea",
          url: "/operations/appointment-front-line",
          icon: "fa-solid fa-stethoscope",
          permission: "patient-management_appointment-front-line"
        },
        {
          name: "Laboratorio - Métodos de Análisis",
          url: "/administration/analysis-methods",
          icon: "fa-solid fa-flask",
          permission: "administration_analysis-methods"
        },
        {
          name: "Laboratorio - Parámetros Pruebas Combinadas",
          url: "/administration/analysis-methods-hemograma",
          icon: "fa-solid fa-flask",
          permission: "administration_analysis-methods-hemograma"
        },
        {
          name: "Motivos de Cancelación de Citas",
          url: "/administration/reason-cancellation",
          icon: "fa-solid fa-file-alt",
          permission: "administration_reason-cancellation"
        },
        {
          name: "Motivos de Suspensión de PAM",
          url: "/patient-management/reasons_for_pam_suspension",
          icon: "fa-solid fa-file-alt",
          permission: "patient-management-reasons_for_pam_suspension"
        },
        {
          name: "Pacientes en Espera",
          url: "/operations/patient-await",
          icon: "fa-solid fa-users",
          permission: "patient-management_appointment-patient-await"
        },
        {
          name: "Pacientes en Proceso de Analíticas",
          url: "/laboratory/analitics-process",
          icon: "fa-solid fa-flask",
          permission: "laboratory_analitics-process"
        },
        {
          name: "Pre-Consulta",
          url: "/medical-management/pre-consultation",
          icon: "fa-solid fa-stethoscope",
          permission: "medical-management_appointment_pre_consultation"
        },
        {
          name: "Procesamiento de Cuadres",
          url: "/operations/settlements",
          icon: "fa-solid fa-check-square",
          permission: "operation_settlements"
        },
        {
          name: "Programas de Atención Médica (PAM's)",
          url: "/operations/medical-attention-programs",
          icon: "fa-solid fa-calendar",
          permission: "operation_medical-attention-programs"
        },
        {
          name: "Punto de Venta",
          url: "/operations/pos",
          icon: "fa-solid fa-shopping-bag",
          permission: "operation_pos"
        },
        {
          name: "Recepción de Conduces",
          url: "/shopping/purchase-conduce-relationship",
          icon: "fa-solid fa-external-link",
          permission: "shopping_purchase-conduce-relationship"
        },
        {
          name: "Toma de Muestra",
          url: "/operations/take-sample",
          icon: "fa-solid fa-flask",
          permission: "laboratory_take-sample"
        },
        {
          name: "Transferencias",
          url: "/transference",
          icon: "fa-solid fa-external-link",
          permission: "shopping_transferences"
        }
      ]
    },
    {
      name: "Contacto",
      url: "/patient-management",
      icon: "fa-solid fa-headset",
      children: [
        {
          icon: "fa-solid fa-indent",
          name: "Citas Médicas - Administración",
          url: "/operations/appointment-admin",
          permission: "patient-management_appointment-admin"
        },
        {
          icon: "fa-solid fa-book",
          name: "Citas Médicas - Agendamiento",
          url: "/operations/appointment-agenda",
          permission: "patient-management_appointment-agenda"
        },
        {
          icon: "fa-solid fa-file-text",
          name: "Citas Médicas - Confirmación",
          url: "/patient-management/appointment-confirm",
          permission: "patient-management-appointment-confirm"
        },
        {
          icon: "fa-solid fa-book",
          name: "Citas Médicas - Reagendamiento",
          url: "/patient-management/appointment-rescheduling",
          permission: "patient-management_appointment-rescheduling"
        },
        {
          icon: "fa-solid fa-users",
          name: "Pacientes",
          url: "/operations/patients",
          permission: "operation_patients"
        },
        {
          icon: "fa-solid fa-users",
          name: "Pacientes en PAM's - Administración",
          url: "/operations/patient-medical-attention-programs",
          permission: "patient-management_patient-medical-attention-programs"
        },
        {
          icon: "fa-solid fa-display",
          name: "Pantalla de Kpi - Contacto",
          url: "/contact/screen-kpi-contact",
          permission: "contact-screen_kpi_contact"
        },
        {
          name: "Recuperación de Contraseñas",
          url: "/patient-management/restart-password",
          icon: "fa-solid fa-lock",
          permission: "patient-management_restart_password"
        }
      ]
    },
    {
      name: "Cuidados",
      url: "",
      icon: "fa-solid fa-stethoscope",
      children: [
        {},
        {
          name: "Alergias",
          icon: "fa-solid fa-capsules",
          url: "/operations/allergies",
          permission: "operation_allergies"
        },
        {
          name: "Captura de Radiografía",
          url: "/medical-management/xray-capture",
          icon: "fa-solid fa-x-ray",
          permission: "patient-management_xray-capture"
        },
        {
          name: "Citas Médicas - Ejecución",
          icon: "fa-solid fa-stethoscope",
          url: "/operations/appointment-internal",
          permission: "patient-management_appointment-internal"
        },
        {
          icon: "fa-solid fa-calendar-day",
          name: "Citas Médicas - Tipos",
          url: "/services/appointments",
          permission: "operation_appointment-types"
        },
        {
          icon: "fa-solid fa-heartbeat",
          name: "Especialidades Médicas",
          url: "/services/specialities",
          permission: "operation_appointment-specialities"
        },
        {
          name: "Historia Clínica",
          url: "/operations/appointment-histories",
          icon: "fa-solid fa-history",
          permission: "patient-management_appointment-histories"
        },
        {
          icon: "fa-solid fa-notes-medical",
          name: "LPC's - Administración",
          url: "/lpc/clinical-profile",
          permission: "administration_clinical_profile"
        },
        {
          icon: "fa-solid fa-file-alt",
          name: "Licencias Médicas - Tipos",
          url: "/operations/medical-licenses",
          permission: "operation_medical-licenses"
        },
        {
          icon: "fa-solid fa-list-alt",
          name: "Parámetros Medicos - Grupos",
          url: "/operations/medical-parameter-groups",
          permission: "operation_medical-parameter-groups"
        },
        {
          icon: "fa-solid fa-list-alt",
          name: "Parámetros Médicos - Administración",
          url: "/operations/medical-parameters",
          permission: "operation_medical-parameters"
        },
        {
          icon: "fa-solid fa-user-md",
          name: "Personal de Cuidados",
          url: "/hmanagement/mstaffs",
          permission: "hmanagement_mstaffs"
        }
      ]
    }
  ]
};
