import JsBarcode from "jsbarcode/bin/JsBarcode";
import moment from "moment";
import React from "react";
export const currencyFormat = ({ currencySymbol = "" }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });

  return amount => currencySymbol + formatter.format(amount || 0);
};

const date = new Date();
export const months = [
  { name: `Enero - ${date.getFullYear()}`, value: "01" },
  { name: `Febrero - ${date.getFullYear()}`, value: "02" },
  { name: `Marzo - ${date.getFullYear()} `, value: "03" },
  { name: `Abril - ${date.getFullYear()}`, value: "04" },
  { name: `Mayo - ${date.getFullYear()}`, value: "05" },
  { name: `Junio - ${date.getFullYear()}`, value: "06" },
  { name: `Julio - ${date.getFullYear()}`, value: "07" },
  { name: `Agosto - ${date.getFullYear()}`, value: "08" },
  { name: `Septiembre - ${date.getFullYear()}`, value: "09" },
  { name: `Octubre - ${date.getFullYear()}`, value: "10" },
  { name: `Noviembre - ${date.getFullYear()}`, value: "11" },
  { name: `Diciembre  - ${date.getFullYear()}`, value: "12" }
];

export const getMonthTextWithMonthNumber = monthNumber => {
  const monthsMaped = months.map(month => {
    const indexOfGuion = month.name.indexOf("-");
    const name = month.name.substring(0, indexOfGuion - 1);
    return { name, value: parseInt(month.value) };
  });
  return monthsMaped.find(m => m.value === monthNumber).name;
};

export const AppointmentType = (
  medicalAttentionProgramId,
  appointment,
  appointmentType
) => {
  if (appointmentType) {
    if (appointmentType === 1) {
      return <span>Regular</span>;
    } else if (appointmentType === 2) {
      return <span>Promoci&oacute;n y Prevenci&oacute;n</span>;
    } else if (appointmentType === 3) {
      return <span>Pre-Empleo</span>;
    }
  } else {
    if (appointment?.isPreEmpleoAppointment === true) {
      return <span>Pre-Empleo</span>;
    } else if (medicalAttentionProgramId) {
      return <span>Promoci&oacute;n y Prevenci&oacute;n</span>;
    } else if (!medicalAttentionProgramId) {
      return <span>Regular</span>;
    }
  }
};

export const filterIncludes = (arr, arrEx, path) => {
  const exclude = arrEx.map(item => item[path]).join(",");
  const arrExclude = arr.filter(item => {
    return !exclude.includes(`${item[path]}`);
  });

  return arrExclude;
};

export const addComaNumber = new Intl.NumberFormat("en-US");

export const sumFooterUtils = (arr, path) => {
  const total = arr.reduce((pre, curr) => {
    return pre + curr[path];
  }, 0);

  return total;
};

export const sumFooterNoAccessorPath = (arr, path) => {
  const total = arr.reduce((pre, curr) => {
    return pre + curr._original[path];
  }, 0);

  return total;
};

export const getDateOfCurrentMonth = () => {
  const date = new Date();
  let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
  let currentDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const ultimoDia = new Date(
    parseInt(moment().format("YYYY")),
    parseInt(moment().format("MM")),
    0
  ).getDate();

  return { primerDia, currentDate, ultimoDia };
};

export const getDayBetweenToday = date => {
  const today = moment();
  const days = today.diff(date, "days");

  return days;
};

export const generateBarCode = text => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {
    format: "CODE128",
    displayValue: true,
    fontSize: 30
  });

  return canvas.toDataURL("image/png");
};

export const orderArrayByParams = (array, params) => {
  const data = array.map(x => ({ ...x, name: x.name.toUpperCase() }));
  const sortdata = data.sort((a, b) => a[params] > b[params]) || [];
  return sortdata;
};
