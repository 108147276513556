import React, { useEffect, useState } from "react";
import TableAverageTimeTypeAppointment from "./averageTime/TableTypeAppointment";
import ChartAverageTime from "./averageTime/Chart";
import TableAverageTicketTypeAppointment from "./averageTicket/TableTypeAppointment";
import ChartAverageTicket from "./averageTicket/Chart";
import { Col, Row, FormGroup, Label, Spinner } from "reactstrap";
import moment from "moment";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import DatePicker from "react-datepicker";

const query = gql`
  query getAverageTimeByAppointmentByTypeAppointment(
    $where: WhereAverageAndTicket
  ) {
    averageTime: getAverageTimeByAppointmentType(where: $where) {
      expectedDuration
      appointmentName
      averageDuration
      appointmentId
    }

    averageTicket: getAverageTicketByAppointmentType(where: $where) {
      expectedValue
      appointmentName
      averageValue
      appointmentId
      generate
    }
  }
`;

const proof = yes =>
  yes
    ? {
        startDate: moment({ day: 1 } /* "2021-10-01" */).toDate(),
        endDate: moment().toDate()
      }
    : {
        startDate: moment({ day: 21, month: 8 }).format("YYYY-MM-DD"),
        endDate: moment({ day: 30, month: 8 }).format("YYYY-MM-DD")
      };

export default function Index() {
  const [state, setState] = useState({
    ...proof(true),
    averageTime: [],
    averageTicket: []
  });

  const { data, loading } = useQuery(query, {
    variables: {
      where: {
        startDate: moment(state.startDate).format("YYYY-MM-DD"),
        endDate: moment(state.endDate).format("YYYY-MM-DD")
      }
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (data && !loading) {
      setState(state => ({ ...state, ...data }));
    }
  }, [data, loading]);

  return (
    <Row>
      <Col xs={12}>
        <Label className="font-weight-bold mt-2 mb-2">Período</Label>
        <Row>
          <Col xs={2}>
            <FormGroup className="d-flex flex-column">
              <Label for="exampleDate">Desde</Label>
              <DatePicker
                className="form-control"
                onSelect={e => setState(state => ({ ...state, startDate: e }))}
                onChange={e => setState(state => ({ ...state, startDate: e }))}
                selected={state.startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Selecciona una fecha hasta"
              />
            </FormGroup>
          </Col>
          <Col xs={2}>
            <FormGroup className="d-flex flex-column">
              <Label for="exampleDate">Hasta</Label>
              <DatePicker
                className="form-control"
                onSelect={e => setState(state => ({ ...state, endDate: e }))}
                onChange={e => setState(state => ({ ...state, endDate: e }))}
                selected={state.endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Selecciona una fecha hasta"
              />
            </FormGroup>
          </Col>
          <Col xs={2}>{loading && <Spinner animation="grow" />}</Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Label className="font-weight-bold mt-2 mb-4">
          Tiempo Promedio por Cita
        </Label>
        <TableAverageTimeTypeAppointment state={state} />
      </Col>
      <Col xs={6} className="">
        <ChartAverageTime state={state} />
      </Col>
      <Col xs={6}>
        <Label className="font-weight-bold mt-4 mb-4">
          Ticket Promedio por Cita
        </Label>
        <TableAverageTicketTypeAppointment state={state} />
      </Col>
      <Col xs={6} className="">
        <ChartAverageTicket state={state} />
      </Col>
    </Row>
  );
}
