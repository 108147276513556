import { lazy } from "react";

const MainPage = lazy(() => import("./page"));

const routes = [
  {
    path: "/contact/screen-kpi-contact",
    component: MainPage,
    exact: false,
    name: "Pantalla de Kpi - Contacto"
  }
];

export default routes;
